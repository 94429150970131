<template>
  <b-card :title="$t('company_info')">
    <b-row v-if="company">
      <b-col sm="4">
        <h5>{{ $t('company') }} {{ company.name }}</h5>
      </b-col>
      <b-col sm="8">
        <table class="table">
          <thead>
          <tr>
            <th>Борг компанії</th>
            <th>Наш борг</th>
            <th>Сальдо</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>100</td>
            <td>50</td>
            <td>-1</td>
          </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
    <h5>{{ $t('contact') }}</h5>
    <Counterparty v-if="counterparties.length > 0" v-for="item in counterparties" :key="item.id" :counterparty="item"/>
    <Operations />
  </b-card>
</template>

<script>
import axios from 'axios'
import Counterparty from './company/counterparty'
import Operations from './company/operations'
export default {
  components: {
    Counterparty,
    Operations
  },
  data: () => ({
    company: null,
    counterparties: null
  }),
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      const id = this.$route.params.id
      await axios.get(apiUrl + 'company/' + id).then(resp => {
        if (resp.data) {
          this.company = resp.data.company
          this.counterparties = resp.data.counterparties
        }
      })
    }
  }
}
</script>
