<template>
  <b-row>
    <b-col sm="12">
      <h6>{{ $t('operations') }}</h6>
    </b-col>
  </b-row>
</template>

<script>
export default {
}
</script>
